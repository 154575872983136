import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Giveaways from '../../components/Page/Giveaways/Giveaways';
import SEO from '../../components/Seo/Seo';
import streamerPicture from './images/tudson.png';

const GiveawayPage = ({ pageContext: { intl } }) => {
  const lang = 'pl';
  const title = 'tudsoN Giveaway!';
  const description =
    'Wypełnij parę prostych zadań i zwiększ swoją szansę na zdobycie wspaniałego skina do M4A4!';

  const hero = {
    title,
    description,
    glemUrl: 'https://gleam.io/mSt6b/tudson-x-skinwallet-giveaway',
    glemLoadingText: 'Wczytuję...',
    glemPlaceholderText: 'Tudson x Skinwallet Giveaway',
  };
  const streamer = {
    title: 'Wygraj Mkę od tudsoNa i Skinwalleta',
    description:
      'Filipa “tudsoNa” Tudeva nie trzeba przdstawiać chyba żadnemu fanowi CS:GO - pro-gamer, streamer i obecny AWPer MAD Lions. Oglądaj stream tudsoNa, wykonuj zadania i zbieraj punkty by mieć szansę na zdobycie wspaniałego M4A4 | The Emperor!',
    image: (
      <LazyLoadImage
        src={streamerPicture}
        alt="tudsoN"
        width={300}
        height={317}
      />
    ),
  };
  const marketURL =
    'https://www.skinwallet.com/market/?ref=tudsontudson&utm_source=partner&utm_campaign=tudsontudson&utm_medium=tudsontudson&utm_content=giveaway';

  return (
    <>
      <SEO
        lang={lang}
        title={title}
        description={description}
        pathname={intl.originalPath}
        robots="noindex, follow"
      />
      <Giveaways
        lang={lang}
        hero={hero}
        streamer={streamer}
        marketURL={marketURL}
      />
    </>
  );
};

GiveawayPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default GiveawayPage;
